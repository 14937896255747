import React from "react"

import SEO from "../components/seo"

const ArtistsPage = () => (
  <>
    <SEO title="Artists" />
    <p>Coming soon</p>
  </>
)

export default ArtistsPage
